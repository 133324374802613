import React, { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  FileInput,
  FileField,
  TextField,
  RadioButtonGroupInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SelectInput,
  FormDataConsumer,
} from "react-admin";
import { Box } from "@material-ui/core";

const ChapterEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput reference="semesters" source="semesterId">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput source="videoId" />
      <TextInput source="tencentPlayback" />
      <TextInput source="length" />
      <TextField source="pdfFilename" />
      <FileInput
        source="attachments"
        accept=".doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        multiple
      >
        <FileField source="src" title="title" />
      </FileInput>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <ReferenceArrayInput
            source="courseIds"
            reference="courses"
            label="Courses"
            filter={{ semesterId: formData.semesterId }}
            {...rest}
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <ReferenceArrayInput
            source="freeCourseIds"
            reference="free_courses"
            label="Free Courses"
            filter={{ semesterId: formData.semesterId }}
            {...rest}
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

export default ChapterEdit;
