import React from "react";
import {
  Edit,
  TabbedForm,
  TextInput,
  FormTab,
  ReferenceManyField,
  Datagrid,
  TextField,
  Pagination,
  ReferenceField,
  ReferenceInput,
  SelectInput,
} from "react-admin";

const FreeCourseEdit = (props) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="summary">
        <TextInput disabled source="id" />
        <TextInput source="name" />
        <ReferenceInput reference="semesters" source="semesterId">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput multiline source="description" />
      </FormTab>
      <FormTab label="chapters">
        <ReferenceManyField
          pagination={<Pagination />}
          reference="course_chapters"
          target="targetId"
          filter={{ targetType: "FreeCourse" }}
          sort={{ field: "position", order: "ASC" }}
          label=""
        >
          <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField reference="chapters" source="chapterId">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="position" />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default FreeCourseEdit;
