import SemesterList from "./SemesterList";
import SemesterCreate from "./SemesterCreate";
import SemesterEdit from "./SemesterEdit";

const semester = {
  list: SemesterList,
  create: SemesterCreate,
  edit: SemesterEdit,
};

export default semester;
