import React from "react";
import {
  Edit,
  TabbedForm,
  TextInput,
  PasswordInput,
  ReferenceArrayInput,
  FormTab,
  AutocompleteArrayInput,
  ReferenceManyField,
  Datagrid,
  DateField,
  TextField,
  BooleanField,
  Pagination,
} from "react-admin";
import { parse } from "query-string";
import ShowActivityButton from "./ShowActivityButton";

const CourseEdit = (props) => {
  const { device_uuid } = parse(props.location.search);
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="summary">
          <BooleanField source="confirmed" />
          <TextInput disabled source="id" />
          <TextInput disabled source="email" />
          <TextInput source="name" />
          <TextInput source="wechatId" label="wechat" />
          <TextInput source="studentId" label="student" />
          <TextInput source="deviceUuid" label="device" />
          <PasswordInput source="password" />
          <ReferenceArrayInput
            source="courseIds"
            reference="courses"
            label="Courses"
            filter={{ currentSemester: true }}
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label="devices">
          <ReferenceManyField
            pagination={<Pagination />}
            reference="devices"
            target="userId"
            label=""
          >
            <Datagrid>
              <DateField source="createdAt" label="Time" showTime />
              <TextField source="uuid" label="Device ID" />
              <TextField source="details.os.name" label="Device Type" />
              <TextField source="details.os.version" label="Device version" />
              <TextField source="details.browser.name" label="Device Browser" />
              <TextField
                source="details.browser.version"
                label="Browser version"
              />
              <BooleanField source="succeed" />
              <ShowActivityButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="activities">
          <ReferenceManyField
            pagination={<Pagination />}
            reference="visits"
            target="userId"
            filter={{ deviceUuid: device_uuid }}
            label=""
          >
            <Datagrid>
              <DateField source="startedAt" label="Time" showTime />
              <TextField source="deviceUuid" label="Device ID" />
              <TextField source="ip" />
              <TextField source="userAgent" />
              <TextField source="referrer" />
              <TextField source="brower" />
              <TextField source="os" />
              <TextField source="deviceType" />
              <TextField source="country" />
              <TextField source="region" />
              <TextField source="city" />
              <TextField source="country" />
              <TextField source="latitude" />
              <TextField source="longitude" />
              <TextField source="appVersion" />
              <TextField source="osVersion" />
              <TextField source="platform" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="comments"></FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default CourseEdit;
