import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  TextInput,
} from "react-admin";
import TabbedDatagrid from "../courses/CourseTabbedDatagrid";

const freeCourseFilters = [<TextInput label="Search" source="q" alwaysOn />];

const FreeCourseList = (props) => (
  <List
    {...props}
    filters={freeCourseFilters}
    perPage={25}
    filterDefaultValues={{ semesterId: "1" }}
  >
    <TabbedDatagrid />
  </List>
);

export default FreeCourseList;
