import React, { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  RadioButtonGroupInput,
  useRedirect,
} from "react-admin";
import { Box } from "@material-ui/core";

const CourseChapterEdit = (props) => {
  const redirect = (basePath, id, data) => {
    if (data.targetType === "Course") {
      return `/courses/${data.targetId}/1`;
    } else {
      return `/free_courses/${data.targetId}/1`;
    }
  };

  return (
    <Edit {...props}>
      <SimpleForm redirect={redirect}>
        <CourseForm />
        <ReferenceInput reference="chapters" source="chapterId">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="position" />
      </SimpleForm>
    </Edit>
  );
};

const CourseForm = ({ record }) => {
  const [courseType, setCourseType] = useState(null);

  useEffect(() => {
    if (record?.targetType === "Course") {
      setCourseType("courses");
    } else if (record?.targetType === "FreeCourse") {
      setCourseType("free_courses");
    }
  }, [record]);

  return (
    <Box display="flex" flexDirection="column" maxWidth="250px">
      <RadioButtonGroupInput
        source="targetType"
        choices={[
          { id: "Course", name: "Course" },
          { id: "FreeCourse", name: "Free Course" },
        ]}
        onChange={(selected) => {
          selected === "Course"
            ? setCourseType("courses")
            : setCourseType("free_courses");
        }}
      />
      {courseType && (
        <ReferenceInput label="Course" source="targetId" reference={courseType}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      )}
    </Box>
  );
};

export default CourseChapterEdit;
