import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

const FreeCourseCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput reference="semesters" source="semesterId">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput multiline source="description" />
    </SimpleForm>
  </Create>
);

export default FreeCourseCreate;
