import ChapterList from "./ChapterList";
import ChapterEdit from "./ChapterEdit";
import ChapterCreate from "./ChapterCreate";

const chapters = {
  list: ChapterList,
  edit: ChapterEdit,
  create: ChapterCreate,
};

export default chapters;
