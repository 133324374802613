import DesktopItemList from "./DesktopItemList";
import DesktopItemEdit from "./DesktopItemEdit";
import DesktopItemCreate from "./DesktopItemCreate";

const desktop_item = {
  list: DesktopItemList,
  edit: DesktopItemEdit,
  create: DesktopItemCreate,
};

export default desktop_item;
