import React from "react";
import { Create, SimpleForm, TextInput, PasswordInput } from "react-admin";

const AdminUserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" />
      <PasswordInput source="password" />
    </SimpleForm>
  </Create>
);

export default AdminUserCreate;
