import React from "react";
import {
  Create,
  TextInput,
  PasswordInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SimpleForm,
} from "react-admin";

const CourseCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="email" />
        <TextInput source="name" />
        <TextInput source="wechatId" label="wechat" />
        <TextInput source="studentId" label="student" />
        <PasswordInput source="password" />
        <ReferenceArrayInput
          source="courseIds"
          reference="courses"
          label="Courses"
          filter={{ currentSemester: true }}
        >
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default CourseCreate;
