import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";

const styles = {
  button: {
    marginLeft: "1em",
  },
};

const ShowActivityButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={`/users/1/2?device_uuid=${record.uuid}`}
    label="show activities"
    title="show activities"
  />
);

export default withStyles(styles)(ShowActivityButton);
