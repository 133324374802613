import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  RadioButtonGroupInput,
} from "react-admin";

const ChapterCreate = (props) => {
  const [courseType, setCourseType] = useState("courses");

  return (
    <Create {...props}>
      <SimpleForm>
        <RadioButtonGroupInput
          source="targetType"
          choices={[
            { id: "Course", name: "Course" },
            { id: "FreeCourse", name: "Free Course" },
          ]}
          onChange={(selected) => {
            selected === "Course"
              ? setCourseType("courses")
              : setCourseType("free_courses");
          }}
        />
        <ReferenceInput label="Course" source="targetId" reference={courseType}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput reference="chapters" source="chapterId">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="position" />
      </SimpleForm>
    </Create>
  );
};

export default ChapterCreate;
