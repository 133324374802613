import { client } from "./dataProvider";
import { gql } from "@apollo/client";

const ADMIN_USER_LOGIN = gql`
  mutation LogInAdminUser(
    $email: String!
    $password: String!
    $otpCode: String!
  ) {
    adminUserLogin(email: $email, password: $password, otpCode: $otpCode) {
      credentials {
        accessToken
        client
        expiry
        tokenType
        uid
      }
    }
  }
`;

const ADMIN_USER_LOGOUT = gql`
  mutation adminUserLogout {
    adminUserLogout {
      authenticatable {
        email
      }
    }
  }
`;

const ME = gql`
  query Me {
    Me {
      id
      name
      email
    }
  }
`;

const authProvider = {
  login: ({ username, password, otp_code }) => {
    return client
      .mutate({
        mutation: ADMIN_USER_LOGIN,
        variables: {
          email: username,
          password: password,
          otpCode: otp_code,
        },
      })
      .then((result) => {
        localStorage.setItem(
          "auth",
          JSON.stringify({
            "access-token": result.data.adminUserLogin.credentials.accessToken,
            client: result.data.adminUserLogin.credentials.client,
            expiry: result.data.adminUserLogin.credentials.expiry,
            "token-type": result.data.adminUserLogin.credentials.tokenType,
            uid: result.data.adminUserLogin.credentials.uid,
          })
        );
      })
      .catch(() => Promise.reject());
  },
  logout: () => {
    client
      .mutate({
        mutation: ADMIN_USER_LOGOUT,
      })
      .then(() => {
        localStorage.removeItem("auth");
        client.cache.reset();
      })
      .catch(() => {
        localStorage.removeItem("auth");
      });

    return Promise.resolve();
  },
  checkError: (error) => {
    if (
      error.message.includes("authentication") &&
      !error.message.includes("schema")
    ) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("auth")
      ? Promise.resolve()
      : Promise.reject({ message: false }),
  getIdentity: () => {
    return client
      .query({ query: ME })
      .then((result) => {
        return Promise.resolve({
          id: result.data.Me.id,
          fullName: result.data.Me.name,
          avatar: "",
        });
      })
      .catch((error) => Promise.reject(error));
  },
  getPermissions: () => {
    // Required for the authentication to work
    return Promise.resolve();
  },
};

export default authProvider;
