import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  FileField,
  FileInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  FormDataConsumer,
} from "react-admin";

const ChapterCreate = (props) => {
  const [courseType, setCourseType] = useState("courses");

  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput reference="semesters" source="semesterId">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" />
        <TextInput source="videoId" />
        <TextInput source="tencentPlayback" />
        <TextInput source="length" />
        <FileInput
          source="attachments"
          accept=".doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          multiple
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceArrayInput
              source="courseIds"
              reference="courses"
              label="Courses"
              filter={{ semesterId: formData.semesterId }}
              {...rest}
            >
              <AutocompleteArrayInput optionText="name" />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceArrayInput
              source="freeCourseIds"
              reference="free_courses"
              label="Free Courses"
              filter={{ semesterId: formData.semesterId }}
              {...rest}
            >
              <AutocompleteArrayInput optionText="name" />
            </ReferenceArrayInput>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default ChapterCreate;
