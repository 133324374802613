import React, { useEffect, useState } from "react";
import { Field, withTypes, useFormState } from "react-final-form";
import { useLocation } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { createTheme, makeStyles, useTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import LockIcon from "@material-ui/icons/Lock";

import { Notification, useTranslate, useLogin, useNotify } from "react-admin";
import { getThemes } from "./layout/themes";

import { client } from "./dataProvider";
import { gql } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    // background: "url(https://source.unsplash.com/random/1600x900)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  otp_code: {
    marginTop: "1em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => {
  return (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
    />
  );
};

const generateOtpCode = gql`
  mutation generateOtpCode($email: String!, $password: String!) {
    generateOtpCode(email: $email, password: $password) {
      status
    }
  }
`;

const OtpField = () => {
  const [loading, setLoading] = useState(false);
  const formState = useFormState();
  const notify = useNotify();
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  });

  const hasCredentials =
    formState.values["username"] && formState.values["password"];

  const sendCode = () => {
    setLoading(true);
    client
      .mutate({
        mutation: generateOtpCode,
        variables: {
          email: formState.values["username"],
          password: formState.values["password"],
        },
      })
      .then(() => {
        setLoading(false);
        setSeconds(60);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning"
        );
      });
  };

  return (
    <>
      <Field
        id="otp_code"
        name="otp_code"
        component={renderInput}
        label="One Time Password"
        disabled={loading}
      />
      <Button
        type="button"
        disabled={loading || seconds > 0 || !hasCredentials}
        size="small"
        onClick={sendCode}
      >
        {loading && <CircularProgress size={15} thickness={2} />}
        Send
        {seconds > 0 && ` (${seconds})`}
      </Button>
    </>
  );
};

const { Form } = withTypes();

const Login = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  const handleSubmit = (auth) => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : "/").catch(
      (error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning"
        );
      }
    );
  };

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon />
                </Avatar>
              </div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    id="username"
                    name="username"
                    component={renderInput}
                    label="Email"
                    type="email"
                    disabled={loading}
                    fullWidth
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    id="password"
                    name="password"
                    component={renderInput}
                    label={translate("ra.auth.password")}
                    type="password"
                    disabled={loading}
                    fullWidth
                  />
                </div>
                <div className={classes.otp_code}>
                  <OtpField loading={loading} setLoading={setLoading} />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  {translate("ra.auth.sign_in")}
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props) => {
  const theme = useTheme();
  const { lightTheme } = getThemes(theme);
  return (
    <ThemeProvider theme={createTheme(lightTheme)}>
      <Login {...props} />
    </ThemeProvider>
  );
};

export default LoginWithTheme;
