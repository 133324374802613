import { List, Datagrid, TextField, BooleanField } from "react-admin";

const SemesterList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <BooleanField source="current" />
    </Datagrid>
  </List>
);

export default SemesterList;
