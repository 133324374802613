import buildGraphQLProvider, { buildQuery } from "ra-data-graphql-simple";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import inflection from "inflection";

const myBuildQuery = (introspection) => (fetchType, resource, params) => {
  const inflectedResource = inflection.singularize(
    inflection.camelize(resource)
  );
  const builtQuery = buildQuery(introspection)(
    fetchType,
    inflectedResource,
    params
  );

  return builtQuery;
};

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_BACKEND_URL
    ? process.env.REACT_APP_BACKEND_URL
    : "http://localhost:4000/admin",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = JSON.parse(localStorage.getItem("auth"));
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...token,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default buildGraphQLProvider({
  buildQuery: myBuildQuery,
  client: client,
});
