import UserList from "./UserList";
import UserEdit from "./UserEdit";
import UserCreate from "./UserCreate";

const users = {
  list: UserList,
  edit: UserEdit,
  create: UserCreate,
};

export default users;
