import CourseList from "./CourseList";
import CourseCreate from "./CourseCreate";
import CourseEdit from "./CourseEdit";

const courses = {
  list: CourseList,
  create: CourseCreate,
  edit: CourseEdit,
};

export default courses;
