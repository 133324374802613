import * as React from "react";
import { Fragment, useCallback, useEffect, useState } from "react";
import {
  Datagrid,
  ListContextProvider,
  TextField,
  useGetList,
  useListContext,
  BooleanField,
} from "react-admin";
import { Divider, Tabs, Tab } from "@material-ui/core";

const useGetSemesters = () => {
  const { data, ids } = useGetList(
    "semesters",
    { page: 1, perPage: 50 },
    { field: "created_at", order: "ASC" }
  );

  if (data) {
    return Object.keys(data).map((k) => data[k]);
  } else {
    return null;
  }
};

const ChapterTabbedDatagrid = (props) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const semesters = useGetSemesters();

  console.log("test");

  const tabs = semesters.map((semester) => ({
    id: semester.id,
    name: semester.name,
  }));

  const handleChange = useCallback(
    (event, value) => {
      setFilters &&
        setFilters(
          { ...filterValues, semesterId: value },
          displayedFilters,
          false
        );
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.semesterId}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab key={choice.id} label={choice.name} value={choice.id} />
        ))}
      </Tabs>
      <Divider />
      <div>
        <ListContextProvider value={{ ...listContext, ids: ids }}>
          <Datagrid
            {...props}
            rowClick="edit"
            data-testid="order-admin-datagrid"
          >
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="pdfFilename" />
          </Datagrid>
        </ListContextProvider>
      </div>
    </Fragment>
  );
};

export default ChapterTabbedDatagrid;
