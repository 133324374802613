import React, { useState, useEffect } from "react";
import buildGraphQLProvider from "./dataProvider";
import authProvider from "./authProvider";
import LoginPage from "./LoginPage";
import { Admin, Resource } from "react-admin";
import users from "./users";
import courses from "./courses";
import chapters from "./chapters";
import admin_users from "./admin_users";
import desktop_items from "./desktop_items";
import free_courses from "./free_courses";
import course_chapters from "./course_chapters";
import semesters from "./semesters";

const App = () => {
  const [dataProvider, setDataProvider] = useState(null);
  useEffect(() => {
    buildGraphQLProvider.then((graphQlDataProvider) =>
      setDataProvider(() => graphQlDataProvider)
    );
  }, []);

  if (!dataProvider) {
    return <div>Loading </div>;
  }

  return (
    <Admin
      title="React Admin"
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={LoginPage}
      disableTelemetry
    >
      <Resource name="users" {...users} />
      <Resource name="courses" {...courses} />
      <Resource name="free_courses" {...free_courses} />
      <Resource name="chapters" {...chapters} />
      <Resource name="admin_users" {...admin_users} />
      <Resource name="devices" />
      <Resource name="visits" />
      <Resource name="desktop_categories" />
      <Resource name="desktop_items" {...desktop_items} />
      <Resource name="course_chapters" {...course_chapters} />
      <Resource name="semesters" {...semesters} />
    </Admin>
  );
};

export default App;
