import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ReferenceField,
} from "react-admin";

const desktopItemFilters = [<TextInput label="Search" source="q" alwaysOn />];

const DesktopItemList = (props) => (
  <List {...props} filters={desktopItemFilters} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="link" />
      <ReferenceField
        label="Category"
        source="desktopCategoryId"
        reference="desktop_categories"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default DesktopItemList;
