import React from "react";
import { Create, SimpleForm, TextInput, BooleanInput } from "react-admin";

const SemesterCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <BooleanInput source="current" />
    </SimpleForm>
  </Create>
);

export default SemesterCreate;
