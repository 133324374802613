import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

const DesktopItemCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Category"
        source="desktopCategoryId"
        reference="desktop_categories"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput source="link" />
    </SimpleForm>
  </Create>
);

export default DesktopItemCreate;
