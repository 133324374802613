import React from "react";
import { Edit, SimpleForm, TextInput, PasswordInput } from "react-admin";

const AdminUserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="email" />
      <PasswordInput source="password" />
    </SimpleForm>
  </Edit>
);

export default AdminUserEdit;
