import FreeCourseList from "./FreeCourseList";
import FreeCourseCreate from "./FreeCourseCreate";
import FreeCourseEdit from "./FreeCourseEdit";

const free_courses = {
  list: FreeCourseList,
  create: FreeCourseCreate,
  edit: FreeCourseEdit,
};

export default free_courses;
