import React, { useState } from "react";
import {
  Edit,
  TabbedForm,
  TextInput,
  FormTab,
  ReferenceManyField,
  Datagrid,
  TextField,
  Pagination,
  ImageInput,
  ImageField,
  FileInput,
  BooleanInput,
  FileField,
  ReferenceInput,
  Button,
  ReferenceField,
  useMutation,
  Confirm,
  SelectInput,
} from "react-admin";

const DeleteUserButton = ({ record }) => {
  const [open, setOpen] = useState(false);

  const [deleteUser, { loading }] = useMutation({
    type: "update",
    resource: "courses",
    payload: { id: record?.id, data: { deleteAllUsers: true } },
  });

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    deleteUser();
    setOpen(false);
  };

  return (
    <>
      <button onClick={handleClick}>Delete All Users Of This Course</button>
      <Confirm
        isOpen={open}
        loading={loading}
        title="Delete All Users of this course"
        content="Are you sure you want to delete all users of this course?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const CourseEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput disabled source="id" />
          <BooleanInput source="active" />
          <ReferenceInput reference="semesters" source="semesterId">
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="name" />
          <TextInput multiline source="description" />
          <TextInput multiline source="wechatAccounts" />
          <TextField source="userWechats" />
          <TextField source="remainingWechats" />
          <TextField source="lackOfWechats" />
          <FileInput source="wechatCsv" accept=".csv">
            <FileField source="src" title="title" />
          </FileInput>
          <ImageField source="imageUrl" />
          <ImageInput source="image" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
          <DeleteUserButton />
        </FormTab>
        <FormTab label="chapters">
          <ReferenceManyField
            pagination={<Pagination />}
            reference="course_chapters"
            target="targetId"
            filter={{ targetType: "Course" }}
            sort={{ field: "position", order: "ASC" }}
            label=""
          >
            <Datagrid rowClick="edit">
              <TextField source="id" />
              <ReferenceField reference="chapters" source="chapterId">
                <TextField source="name" />
              </ReferenceField>
              <TextField source="position" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="users">
          <ReferenceManyField
            pagination={<Pagination />}
            reference="users"
            target="courseId"
            label=""
          >
            <Datagrid rowClick="edit">
              <TextField source="id" />
              <TextField source="wechatId" />
              <TextField source="email" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default CourseEdit;
