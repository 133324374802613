import { List, Datagrid, TextField } from "react-admin";

const AdminUserList = (props) => (
  <List {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="email" />
    </Datagrid>
  </List>
);

export default AdminUserList;
