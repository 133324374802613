import { List, Datagrid, TextField, TextInput } from "react-admin";
import TabbedDatagrid from "./ChapterTabbedDatagrid";

const chapterFilters = [<TextInput label="Search" source="q" alwaysOn />];

const ChapterList = (props) => (
  <List
    {...props}
    filters={chapterFilters}
    perPage={25}
    filterDefaultValues={{ semesterId: "1" }}
  >
    <TabbedDatagrid />
  </List>
);

export default ChapterList;
