import {
  List,
  Datagrid,
  TextField,
  TextInput,
  BooleanField,
  BooleanInput,
} from "react-admin";

const userFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <BooleanInput source="confirmed" alwaysOn />,
];

const UserList = (props) => (
  <List
    {...props}
    filters={userFilters}
    filterDefaultValues={{ confirmed: true }}
    perPage={25}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="wechatId" />
      <TextField source="studentId" />
      <TextField source="email" />
      <BooleanField source="confirmed" />
    </Datagrid>
  </List>
);

export default UserList;
