import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  BooleanInput,
  FileInput,
  FileField,
  ReferenceInput,
  SelectInput,
} from "react-admin";

const CourseCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <ReferenceInput reference="semesters" source="semesterId">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput multiline source="description" />
      <TextInput multiline source="wechatAccounts" />
      <FileInput source="wechatCsv" accept=".csv">
        <FileField source="src" title="title" />
      </FileInput>
      <ImageInput source="image" accept="image/*">
        <ImageField source="src" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export default CourseCreate;
